var pomlo_app = {};

// Define constants
pomlo_app.BREAKPOINTS = {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1140
};
pomlo_app.DEFAULT_DURATION = 300;
pomlo_app.HEADER_HEIGHT = 160;

pomlo_app.toggleHeaderStyle = function() {

  function setSmallHeader() {
    jQuery('body').addClass('header--small');
    jQuery('#navbar-container').addClass('navbar--small');
  }

  function unsetSmallHeader() {
    jQuery('body').removeClass('header--small');
    jQuery('#navbar-container').removeClass('navbar--small');
  }

  if ((jQuery(window).width() < pomlo_app.BREAKPOINTS.MD) || (jQuery(window).scrollTop() > pomlo_app.HEADER_HEIGHT)) {
    setSmallHeader();
  } else {
    unsetSmallHeader();
  }
};

jQuery(document).ready(function($) {
  pomlo_app.toggleHeaderStyle();

  jQuery('#navbar__toggler--open, #navbar__toggler--close')
    .click(function () {
      jQuery('#navbar__mobile')
        .toggleClass('active');
    });

  if(jQuery('.splide').length) {
    var splide = new Splide( '.splide', {
      type   : 'loop',
      focus  : 'center',
      pauseOnHover: false,
      slideFocus  : false,
      pagination  : false,
      perPage: 1,
      autoplay: true,
    });
    splide.mount();
  }

  if(jQuery('.splide-doubled').length) {
    var splide = new Splide( '.splide.splide-doubled', {
      type : 'loop',
      perPage: 2,
      autoplay: true,
      pauseOnHover: false,
      gap: '1rem',
      pagination: false,
      breakpoints: {
        768: {
          perPage: 1,
        },
      }
    });
    splide.mount();
  }

});

jQuery(window).scroll(function() {
  pomlo_app.toggleHeaderStyle();
});

jQuery(window).resize(function() {
  pomlo_app.toggleHeaderStyle();
});
